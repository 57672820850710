import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostCard from "../components/postCard"

const IndexPage = ({ data: { allContentfulPost } }) => (
  <Layout>
    <SEO title="Home"/>
    <h1>Blog</h1>
    <p>Cada día es una nueva experiencia. En este blog encontrarás posts relativos
      al desarrollo de software.</p>
    <h2>Posts</h2>
    <div className="row">
      {allContentfulPost.edges.map(({ node }) => (
        <article className="col-xs-12 col-sm-6 col-md-4" key={node.id}>
          <PostCard post={node}/>
        </article>
      ))}
    </div>
  </Layout>
)

export default IndexPage
export const query = graphql`
  {
    allContentfulPost(sort: {order: ASC, fields: createdAt}) {
      edges {
        node {
          id
          title
          slug
          createdAt
          image {
            title
            file {
              url
            }
          }
          shortDescription
        }
      }
    }
  }
`
