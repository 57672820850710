import { Link } from "gatsby";
import React from "react";
import PropTypes from 'prop-types';

const PostCard = ({post}) => {
  return (
    <div className="card">
      <img alt={post.image.title} src={post.image.file.url} title={post.image.title} className="card-img-top"/>
      <section className="card-body">
        <h3 className="card-title">{post.title}</h3>
        <p className="card-text">{post.shortDescription}</p>
        <p className="card-text text-muted">{new Intl.DateTimeFormat('es-MX').format(new Date(post.createdAt))}</p>
        <Link to={`/posts/${post.slug}`} className="btn btn-primary">Ver post</Link>
      </section>
    </div>
  );
};

PostCard.propTypes = {
  post: PropTypes.exact({
    id: PropTypes.string,
    title: PropTypes.string,
    createdAt: PropTypes.string,
    shortDescription: PropTypes.string,
    slug: PropTypes.string,
    image: PropTypes.exact({
      title: PropTypes.string,
      file: PropTypes.exact({
        url: PropTypes.string
      })
    })
  }).isRequired
}

export default PostCard;
